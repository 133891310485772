<template>
  <div class="grid p-fluid">
    <div class="col-12">
      <p class="font-bold">
        <span>
          {{ t('autocbm.projects.management.evaluation.upload.failureReport.title') }}
      </span>
                <Message severity="info" :closable="false">{{ t('autocbm.projects.management.evaluation.upload.failureReport.description') }}</Message>
      </p>
      <FileUpload id="upload" name="datasource" accept="application/json" :maxFileSize="1000000 * 500" :multiple="false"
                  :chooseLabel="t('app.form.label.choose')" :uploadLabel="t('app.form.label.upload')" :cancelLabel="t('app.form.label.cancel')"
                  :showUploadButton="uploadForm.uploadPossible" :url="requestData.uploadUrl"
                  @select="select" @before-send="upload" @progress="uploadProgress" @upload="uploadSuccess" @error="uploadError" @clear="cancel"/>
    </div>
  </div>
</template>

<script>
import {reactive, computed} from 'vue'
import {useStore} from 'vuex'
import {useI18n} from 'vue-i18n'
import {useVuelidate} from '@vuelidate/core'
import {required} from '@vuelidate/validators'
import {useMessage} from '@/dockone/app/message'
import {find} from 'lodash/collection'
import {endsWith} from 'lodash/string'
import {useRouter} from "vue-router";

export default {
  name: 'FailureReportsUpload',
  props: {},
  emits: [],
  setup(props, {emit}) {
    const store = useStore()
    const {t} = useI18n()
    const message = useMessage()
    const router = useRouter()


    const rules = {
      datasourceName: {required}
    }
    const uploadForm = reactive({
      datasourceName: '',
      files: [],
      failureReportsFile: computed(() => find(uploadForm.files, file => endsWith(file.name, '.json'))),
      selectedFiles: computed(() => uploadForm.files.length),
      uploadPossible: computed(() => uploadForm.selectedFiles === 1 && !!uploadForm.failureReportsFile),
      loading: false,
      progress: null
    })
    const v$ = useVuelidate(rules, uploadForm)

    const requestData = reactive({
      projectId: computed(() => store.getters['autocbm/project/projectId']),
      uploadUrl: computed(() => process.env.APP_BASE_URL + process.env.APP_SERVICE_URL + `/api/management/ml/projects/${requestData.projectId}/failure_report`), //TODO Create endpoint in ml manager
      //uploadUrl: computed(() => process.env.APP_BASE_URL + process.env.APP_SERVICE_URL + `/api/management/ml/projects/${requestData.projectId}/failure_report`), //TODO Create endpoint in ml manager
      accessToken: computed(() => store.getters['authentication/accessToken'])
    })

    const actions = {
      cancel() {
        uploadForm.datasourceName = ''
        uploadForm.files = []
      },
      select({files}) {
        uploadForm.files = files
      },
      upload({xhr, formData}) {
        xhr.setRequestHeader('Authorization', `Bearer ${requestData.accessToken}`)
        if (formData.has('datasource')) {
          const files = formData.getAll('datasource')
          const dataStructure = find(files, file => endsWith(file.name, '.json'))
          if (dataStructure) {
            formData.append('file', dataStructure)
            formData.delete('datasource')
          }
        }
        uploadForm.loading = true
      },
      uploadProgress({progress}) {
        uploadForm.progress = progress
      },
      uploadSuccess() {
        actions.cancel()
        message.success(t('autocbm.projects.management.data.load.sources.upload.action.upload.success.summary'),
            t('autocbm.projects.management.data.load.sources.upload.action.upload.success.detail'))
        emit('update:visible', false)
        emit('upload')
        console.log('before reload')
        router.go()
        console.log('reload')
      },
      uploadError() {
        actions.cancel()
        message.error(t('autocbm.projects.management.data.load.sources.upload.action.upload.error.summary'),
            t('autocbm.projects.management.data.load.sources.upload.action.upload.error.detail'))
        emit('update:visible', false)
      }
    }

    return {t, v$, uploadForm, requestData, ...actions}
  }
}
</script>

<style lang="scss">
.data-source-upload .p-fileupload .p-fileupload-content {
  .p-progressbar {
    height: 1rem;
  }
}
</style>
