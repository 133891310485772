<template>
  <ProjectLayout @cancel="returnToProjects" @back="$emit('step', 'project_training')"
                 :forwardLabel="t('autocbm.projects.management.form.label.finish')" @forward="finalizeProject" :forwardLoading="project.data.finishLoading">
    <template #mainContent>
      <EvaluationOverview :values="ml.data.evaluation" :loading="ml.data.trainingLoading || ml.data.evaluationInitLoading || ml.data.evaluationUpdateLoading" />
    </template>
    <template #detailsContent>
      <FailureReportUpload />
      <ModelTunings :values="ml.data.evaluationModels" :loading="ml.data.trainingLoading || ml.data.evaluationInitLoading"
                    @updateEvaluation="ml.updateEvaluation(project.data.projectId)" />
    </template>
  </ProjectLayout>
</template>

<script>
import ProjectLayout from '../../ProjectLayout'
import EvaluationOverview from './EvaluationOverview'
import ModelTunings from './ModelTunings'
import FailureReportUpload from "./FailureReportUpload";

import { reactive, computed, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useMessage } from '@/dockone/app/message'
import { cloneDeep } from 'lodash/lang'

export default {
  name: 'Evaluation',
  components: { ProjectLayout, EvaluationOverview, ModelTunings, FailureReportUpload },
  setup () {
    const router = useRouter()
    const store = useStore()
    const { t } = useI18n()
    const message = useMessage()

    const projectData = reactive({
      projectId: computed(() => store.getters['autocbm/project/projectId']),
      finishLoading: false
    })
    const projectActions = {
      finish () {
        projectData.finishLoading = true
        return store.dispatch('autocbm/project/finish')
            .catch(() => message.error(t('autocbm.projects.management.action.finish.error.summary'),
                t('autocbm.projects.management.action.finish.error.detail')))
            .finally(() => projectData.finishLoading = false)
      }
    }
    const project = { data: projectData, ...projectActions }

    const mlData = reactive({
      trainingProgress: computed(() => store.getters['autocbm/projectMl/trainingProgress']),
      trainingResult: computed(() => store.getters['autocbm/projectMl/trainingProgress']),
      trainingSuccessful: computed(() => mlData.trainingProgress === 100 && ml.data.trainingResult),
      trainingLoading: false,
      evaluationInitLoading: false,
      evaluationUpdateLoading: false,
      evaluation: computed(() => store.getters['autocbm/projectMl/evaluation']),
      evaluationModels: null
    })
    const mlActions = {
      loadTrainingStatus (projectId) {
        ml.data.trainingLoading = true
        return store.dispatch('autocbm/projectMl/loadTrainingStatus', projectId)
            .catch(() => message.error(t('autocbm.projects.management.training.action.training.status.error.summary'),
                t('autocbm.projects.management.training.action.training.status.error.detail')))
            .finally(() => ml.data.trainingLoading = false)
      },
      loadEvaluation (projectId) {
        ml.data.evaluationInitLoading = true
        return store.dispatch('autocbm/projectMl/loadEvaluation', projectId)
            .then(evaluation => mlData.evaluationModels = cloneDeep(evaluation).map(model => ({ modelId: model.modelId, modelName: model.modelName })))
            .catch(() => message.error(t('autocbm.projects.management.evaluation.action.evaluation.load.error.summary'),
                t('autocbm.projects.management.evaluation.action.evaluation.load.error.detail')))
            .finally(() => ml.data.evaluationInitLoading = false)
      },
      updateEvaluation (projectId) {
        ml.data.evaluationUpdateLoading = true
        return store.dispatch('autocbm/projectMl/loadEvaluation', projectId)
            .catch(() => message.error(t('autocbm.projects.management.evaluation.action.evaluation.load.error.summary'),
                t('autocbm.projects.management.evaluation.action.evaluation.load.error.detail')))
            .finally(() => ml.data.evaluationUpdateLoading = false)
      }
    }
    const ml = { data: mlData, ...mlActions }

    const returnToProjects = () => router.push({ name: 'projects' })
    const finalizeProject = () => project.finish().then(() => router.push({ name: 'projects' }))

    watch(() => project.data.projectId, projectId => {
      if (projectId) {
        ml.loadTrainingStatus(projectId)
      }
    }, { immediate: true })
    watch(() => ml.data.trainingSuccessful, trainingSuccessful => {
      if (trainingSuccessful) {
        ml.loadEvaluation(project.data.projectId)
      }
    }, { immediate: true })

    return { t, project, ml, returnToProjects, finalizeProject }
  }
}
</script>
