<template>
  <div class="grid p-fluid">
    <div class="col-12">
      <DataTable :value="data.values" dataKey="modelId" stripedRows
                 :paginator="true" :rows="rows" :rowsPerPageOptions="rowsPerPageOptions"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :currentPageReportTemplate="t('autocbm.projects.management.evaluation.form.pagination')">
        <Column field="modelName" :header="t('autocbm.projects.management.evaluation.form.label.modelName')">
          <template v-if="loading" #body>
            <Skeleton height="1.25rem" width="10rem" />
          </template>
        </Column>
        <Column field="algorithmName" :header="t('autocbm.projects.management.evaluation.form.label.algorithmName')">
          <template v-if="loading" #body>
            <Skeleton height="1.25rem" width="10rem" />
          </template>
        </Column>
        <Column field="createdAt" :header="t('autocbm.projects.management.evaluation.form.label.createdAt')">
          <template v-if="loading" #body>
            <Skeleton height="1.25rem" width="10rem" />
          </template>
          <template v-else #body="{ data: value }">
            {{ displayTimestamp(value.createdAt) }}
          </template>
        </Column>
        <Column field="precision" :header="t('autocbm.projects.management.evaluation.form.label.precision')">
          <template v-if="loading" #body>
            <Skeleton height="1.25rem" width="10rem" />
          </template>
          <template v-else #body="{ data: value }">
            {{ Number(value.precision).toFixed(2) }} %
          </template>
        </Column>
        <Column field="recall" :header="t('autocbm.projects.management.evaluation.form.label.recall')">
          <template v-if="loading" #body>
            <Skeleton height="1.25rem" width="10rem" />
          </template>
          <template v-else #body="{ data: value }">
            {{ Number(value.recall).toFixed(4) }}
          </template>
        </Column>
        <Column field="fScore" :header="t('autocbm.projects.management.evaluation.form.label.fScore')">
          <template v-if="loading" #body>
            <Skeleton height="1.25rem" width="10rem" />
          </template>
          <template v-else #body="{ data: value }">
            {{ Number(value.f1_score).toFixed(4)}}
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { reactive, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { DateTime } from 'luxon'

export default {
  name: 'EvaluationOverview',
  components: {},

  props: {
    values: { type: Array },
    loading: { type: Boolean },
    rows: { type: Number, default: 5 },
    rowsPerPageOptions: { type: Array, default: () => [5, 10, 15] }
  },
  setup (props) {
    const { t } = useI18n()

    const data = reactive({
      values: computed(() => {
        if (props.values) {
          return props.values
        } else if (props.loading) {
          return new Array(props.rows)
        } else {
          return null
        }
      })
    })
    const actions = {
      displayTimestamp (timestamp) {
        return DateTime.fromISO(timestamp, { zone: 'UTC' }).toLocal().toLocaleString(DateTime.DATETIME_MED)
      }
    }

    return { t, data, ...actions }
  }
}
</script>
