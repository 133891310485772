<template>
  <div class="model-tuning">
    <div class="grid p-fluid mt-3 mb-3">
      <div class="col-12 md:col-8 lg:col-8 xl:col-6">
        <template v-if="loading">
          <Skeleton height="1.25rem" width="10rem" class="mb-3"/>
          <Skeleton width="100%" height="1rem"/>
        </template>
        <template v-else>
          <p class="font-bold">
            <span>{{ t('autocbm.projects.management.evaluation.form.label.warnBorder') }}: {{ data.warnBorderValue }}</span>
            <Badge value="i" class="ml-2" v-tooltip.right="t('autocbm.projects.management.evaluation.help.warnBorder')"/>
          </p>
          <Slider id="select" v-model="data.warnBorderValue" :min="0.00" :max="1.00" :step="0.01" @slideend="updateWarnBorder"/>
        </template>
      </div>
      <div class="col-12 md:col-3 lg:col-2 col-offset-0 md:col-offset-1 lg:col-offset-2 xl:col-offset-4">
        <Skeleton v-if="loading" height="1.5rem" width="10rem"/>
        <p v-else class="font-bold">{{ t('autocbm.projects.management.evaluation.form.label.precision') }}: {{ Number(precision).toFixed(2) }} %</p>
        <Button icon="pi pi-window-maximize" :label="t('autocbm.projects.management.data.grafana.fullscreen.button.text')"  @click="data.visibleFull = true" class="p-button-primary"/>
        <Sidebar v-model:visible="data.visibleFull" :baseZIndex="1000" position="full">
          <iframe :src="data.dashboardUrl" width="100%" height="600" frameborder="0"></iframe>
          <div class="grid formgrid">
            <div class="col-12 mb-6 lg:col-6 lg:col-6">
              <Panel collapsed="true" :header=" t('autocbm.projects.management.data.grafana.help.usage.header')" class="ml-2 mt-2" :toggleable="true">
                <p>{{ t('autocbm.projects.management.data.grafana.help.usage.description') }}</p>
              </Panel>
            </div>
            <div class="col-12 mb-6 lg:col-6 lg:col-6">
              <Panel collapsed="true" :header="t('autocbm.projects.management.data.grafana.help.label.header')" class="ml-2 mt-2" :toggleable="true">
                <p>{{ t('autocbm.projects.management.data.grafana.help.label.description') }}</p>
              </Panel>
            </div>
          </div>
        </Sidebar>
      </div>
    </div>
    <div class="grid p-fluid mb-3">
      <div class="col-12">
        <Message severity="info" :closable="false">{{ t('autocbm.projects.management.evaluation.anomalies.description') }}</Message>
      </div>
    </div>
    <div class="grid p-fluid">
      <div class="col-12">
        <DataTable :value="data.anomalieValues" :selection="selectedAnomalies" @update:selection="updateSelectedAnomalies"
                   dataKey="id" stripedRows :paginator="true" :rows="rows" :rowsPerPageOptions="rowsPerPageOptions"
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                   :currentPageReportTemplate="t('autocbm.projects.management.evaluation.anomalies.form.pagination')">
          <template #header>
            <div class="table-header">{{ t('autocbm.projects.management.evaluation.anomalies.form.label.detected') }}</div>
          </template>
          <Column selectionMode="multiple" headerStyle="width: 4em"/>
          <Column field="time" :header="t('autocbm.projects.management.evaluation.form.label.time')">
            <template v-if="loading" #body>
              <Skeleton height="1.25rem" width="10rem"/>
            </template>
            <template v-else #body="{ data: value }">
              {{ displayTimestamp(value.time) }}
            </template>
          </Column>
          <Column field="aaaa" :header="t('autocbm.projects.management.evaluation.anomalies.form.label.rating')">
            <template v-if="loading" #body>
              <Skeleton height="1.25rem" width="10rem"/>
            </template>
            <template v-else #body="{ data: value }">
              {{ value.rating }}
            </template>
          </Column>
          <Column field="field" :header="t('autocbm.projects.management.evaluation.form.label.field')">
            <template v-if="loading" #body>
              <Skeleton height="1.25rem" width="10rem"/>
            </template>
          </Column>
          <Column field="value" :header="t('autocbm.projects.management.evaluation.form.label.value')">
            <template v-if="loading" #body>
              <Skeleton height="1.25rem" width="10rem"/>
            </template>
          </Column>
          <template #paginatorstart>
            <DispatchStatus :loading="loading" :saving="data.selectedAnomaliesSaving"
                            :saved="data.selectedAnomaliesSaved" :error="data.selectedAnomaliesError" textIcon text/>
          </template>
          <template #paginatorend></template>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import {reactive, computed, watch} from 'vue'
import {useI18n} from 'vue-i18n'
import {ceil} from 'lodash'
import {sortBy} from 'lodash/collection'
import {DateTime} from 'luxon'
import {useStore} from "vuex";

export default {
  name: 'ModelTuning',
  props: {
    anomalies: {type: Array},
    selectedAnomalies: {type: Array},
    selectedAnomaliesDispatch: {type: Object},
    warnBorder: {type: Number},
    precision: {type: Number},
    loading: {type: Boolean},
    rows: {type: Number, default: 10},
    rowsPerPageOptions: {type: Array, default: () => [10, 15, 20, 25, 50]}
  },
  emits: ['update:warnBorder', 'update:selectedAnomalies'],
  setup(props, {emit}) {
    const {t} = useI18n()
    const store = useStore()

    const data = reactive({
      visibleFull: false,
      dashboardUrl: computed(() => store.getters['autocbm/project/dashboardUrl']),
      warnBorderValue: props.warnBorder,
      anomalieValues: computed(() => {
        if (props.anomalies) {
          return sortBy(props.anomalies, ['time'])
        } else if (props.loading) {
          return new Array(props.rows)
        } else {
          return null
        }
      }),
      selectedAnomaliesSaving: computed(() => props.selectedAnomaliesDispatch ? props.selectedAnomaliesDispatch.pending > 0 : false),
      selectedAnomaliesSaved: computed(() => !!props.selectedAnomaliesDispatch && props.selectedAnomaliesDispatch.success),
      selectedAnomaliesError: computed(() => props.selectedAnomaliesDispatch ? props.selectedAnomaliesDispatch.error : false),
      precision: computed(() => {
        if (props.anomalies && props.anomalies.length > 0 && props.selectedAnomalies) {
          return ceil(100 / props.anomalies.length * props.selectedAnomalies.length, 2)
        } else {
          return null
        }
      })
    })
    const actions = {
      updateWarnBorder() {
        emit('update:warnBorder', data.warnBorderValue)
      },
      updateSelectedAnomalies(selectedAnomalies) {
        emit('update:selectedAnomalies', selectedAnomalies)
      },
      displayTimestamp(timestamp) {
        return DateTime.fromISO(timestamp, {zone: 'UTC'}).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
      }
    }

    watch(() => props.warnBorder, warnBorder => {
      if (warnBorder != null) {
        data.warnBorderValue = warnBorder
      }
    }, {immediate: true})

    return {t, data, ...actions}
  }
}
</script>
