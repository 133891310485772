<template>
  <div class="grid p-fluid">
    <div class="col-12">
      <TabView v-if="loading">
        <TabPanel v-for="value in 2" :key="`model-evaluation-${value}`">
          <template #header>
            <Skeleton width="10rem" height="1.25rem" />
          </template>
          <ModelTuning :anomalies="null" :loading="true" />
        </TabPanel>
      </TabView>
      <TabView v-else lazy v-model:active-index="ml.data.currentModelIndex">
        <TabPanel v-for="value in values" :key="value.modelId" :header="value.modelName">
          <ModelTuning :anomalies="ml.data.evaluationAnomalieValues"
                       v-model:selectedAnomalies="ml.data.selectedAnomalies" :selectedAnomaliesDispatch="ml.data.selectedAnomaliesDispatch"
                       v-model:warnBorder="ml.data.evaluationAnomalieWarnBorder"
                       :precision="ml.data.evaluationAnomaliePrecision"
                       :loading="ml.data.evaluationAnomaliesLoading" />
        </TabPanel>
      </TabView>
    </div>
  </div>
</template>

<script>
import ModelTuning from './ModelTuning'

import { reactive, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useMessage } from '@/dockone/app/message'
import { useDispatcher } from '@/dockone/components/dispatcher'

export default {
  name: 'ModelTunings',
  components: { ModelTuning },
  props: {
    values: { type: Array },
    loading: { type: Boolean }
  },
  emits: ['updateEvaluation'],
  setup (props, { emit }) {
    const store = useStore()
    const { t } = useI18n()
    const { dispatches, handleDispatch, handleUpdate } = useDispatcher(['selectedAnomalies'])
    const message = useMessage()

    const projectData = reactive({
      projectId: computed(() => store.getters['autocbm/project/projectId'])
    })
    const project = { data: projectData }

    const mlData = reactive({
      currentModelIndex: 0,
      currentModel: computed(() => props.values && props.values.length > 0 ? props.values[mlData.currentModelIndex] : null),
      currentModelId: computed(() => mlData.currentModel ? mlData.currentModel.modelId : null),
      evaluationAnomaliesLoading: false,
      evaluationAnomalies: computed(() => store.getters['autocbm/projectMl/evaluationAnomalies']),
      evaluationAnomalieWarnBorder: computed({
        get: () => mlData.evaluationAnomalies ? mlData.evaluationAnomalies.warnBorder : null,
        set: warnBorder => {
          const projectId = project.data.projectId
          const modelId = mlData.currentModelId
          mlData.evaluationAnomaliesLoading = true
          return store.dispatch('autocbm/projectMl/selectEvaluationAnomalieWarnBorder', { projectId, modelId, warnBorder })
              .then(() => emit('updateEvaluation'))
              .catch(() => message.error(t('autocbm.projects.management.evaluation.action.evaluationAnomalies.warnBorder.error.summary'),
                  t('autocbm.projects.management.evaluation.action.evaluationAnomalies.warnBorder.error.detail')))
              .finally(() => mlData.evaluationAnomaliesLoading = false)
        }
      }),
      evaluationAnomaliePrecision: computed(() => mlData.evaluationAnomalies ? mlData.evaluationAnomalies.precision : null),
      evaluationAnomalieValues: computed(() => mlData.evaluationAnomalies ? mlData.evaluationAnomalies.anomalies : null),
      selectedAnomalies: computed({
        get: () => mlData.evaluationAnomalies ? mlData.evaluationAnomalies.selectedAnomalies : null,
        set: selectedAnomalies => {
          const projectId = project.data.projectId
          const modelId = mlData.currentModelId
          return handleDispatch('selectedAnomalies', store.dispatch('autocbm/projectMl/selectEvaluationAnomalies', { projectId, modelId, selectedAnomalies }))
              .then(() => emit('updateEvaluation'))
        }
      }),
      selectedAnomaliesDispatch: computed(() => dispatches.get('selectedAnomalies')),
      selectedAnomaliesSaving: computed(() => mlData.selectedAnomaliesDispatch.pending > 0),
      selectedAnomaliesSaved: computed(() => !!mlData.selectedAnomaliesDispatch && mlData.selectedAnomaliesDispatch.success),
      selectedAnomaliesError: computed(() => mlData.selectedAnomaliesDispatch.error)
    })
    const mlActions = {
      loadEvaluationAnomalies (projectId, modelId) {
        mlData.evaluationAnomaliesLoading = true
        return store.dispatch('autocbm/projectMl/loadEvaluationAnomalies', { projectId, modelId })
            .catch(() => message.error(t('autocbm.projects.management.evaluation.action.evaluationAnomalies.load.error.summary'),
                t('autocbm.projects.management.evaluation.action.evaluationAnomalies.load.error.detail')))
            .finally(() => mlData.evaluationAnomaliesLoading = false)
      }
    }
    const ml = { data: mlData, ...mlActions }

    watch(() => ml.data.currentModelId, currentModelId => {
      if (currentModelId) {
        ml.loadEvaluationAnomalies(project.data.projectId, currentModelId)
      }
    }, { immediate: true })
    watch(() => ml.data.selectedAnomalies, selectedAnomalies => {
      handleUpdate('selectedAnomalies', selectedAnomalies)
    }, { immediate: true })

    return { t, project, ml }
  }
}
</script>
